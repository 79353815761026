import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'

import feather from 'vue-icon'
Vue.use(feather, 'v-icon')

import store from './store'
//import VueEditableGrid from 'vue-editable-grid'
//import 'vue-editable-grid/dist/VueEditableGrid.css'
//Vue.component('vue-editable-grid', VueEditableGrid)

import 'vue-search-select/dist/VueSearchSelect.css'

import AuthHelper from "./views/utils/AuthHelper"
Vue.mixin(AuthHelper)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.prototype.bus = new Vue()

// vue-good-table
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

//Vue.use(require('vue-moment'))
//Vue.use(require('moment-timezone'))

import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)

//import VueQrcodeReader from "vue-qrcode-reader";
//Vue.use(VueQrcodeReader);

//import VueGoogleCharts from 'vue-google-charts'
//Vue.use(VueGoogleCharts)

import './registerServiceWorker'

Vue.mixin({
  methods: {
    getOrgState() {
      return(this.$store.state.orgState)
    },

    getTaxes() {
      return(this.$store.state.taxes)
    },

    getSaleOrderLocations(){
      return(this.$store.state.mod_SaleOrder['locations'])
    },

    getPurchaseOrderLocations(){
      return(this.$store.state.mod_PurchaseOrder['locations'])
    },
  
    saveUserProfile(orgId, orgName, orgState, customReports, taxes, betaTest, 
                    mods, mod_Inventory, mod_SaleOrder, mod_PurchaseOrder, mod_SystemAdmin){
      var self = this
      return new Promise(function(resolve, reject) {
          //self.$store.commit('set', ['orgId', orgId])
          self.$store.commit('set', ['orgName', orgName])
          self.$store.commit('set', ['orgState', orgState])
          self.$store.commit('set', ['customReports', customReports])
          self.$store.commit('set', ['taxes', taxes])
          self.$store.commit('set', ['betaTest', betaTest])

          self.$store.commit('set', ['mods', mods])
          self.$store.commit('set', ['mod_Inventory', mod_Inventory])
          self.$store.commit('set', ['mod_SaleOrder', mod_SaleOrder])
          self.$store.commit('set', ['mod_PurchaseOrder', mod_PurchaseOrder])
          self.$store.commit('set', ['mod_SystemAdmin', mod_SystemAdmin])
          resolve('saved')
      });
    },

  },
})



new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
})
