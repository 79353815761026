import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAWPzue15u4PZlzwyEcWwWhnumt84lQPtA",
  authDomain: "inventory-308102.firebaseapp.com",
  projectId: "inventory-308102",
  messagingSenderId: "79789578776",
  appId: "1:79789578776:web:c4c9fb80b3e80ac6ee816d",
  databaseURL: "https://inventory-308102-default-rtdb.firebaseio.com",
}

// init firebase
const App = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(App);
export const db = getFirestore(App);

