import { firebaseAuth } from './firebaseConfig.js';
import createPersistedState from "vuex-persistedstate"

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
	isLoggedIn: firebaseAuth.currentUser != null,
	user: firebaseAuth.currentUser,

  sidebarShow: 'responsive',
  sidebarMinimize: false,

  orgId: '',
  orgName: '',
  orgState: '',
  taxes: '',
  betaTest: false,
  retailLocation: '',
  onlineLocation: '',

  mods: '',
  mod_Inventory: '',
  mod_SaleOrder: '',
  mod_PurchaseOrder: '',
  mod_SystemAdmin: '',
}

const mutations = {
	authStatusChange (state) {
		state.isLoggedIn = firebaseAuth.currentUser != null;
		state.user = firebaseAuth.currentUser;
	},

  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations,
  plugins: [createPersistedState()]
})