import Vue from 'vue'
import Router from 'vue-router'

import VueGoodTable from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTable)

// General/Common
const Home = () => import('@/views/inventory/Home')
const Contact = () => import('@/views/admin/Contact')
const UserManual = () => import('@/views/admin/UserManual')
const Partners = () => import('@/views/admin/Partners')

// System Admin
const Org = () => import('@/views/admin/Org')
const Item1s = () => import('@/views/admin/Item1s')
const Locations = () => import('@/views/admin/Locations')
const CustomReportsCrud = () => import('@/views/admin/CustomReportsCrud')
const DashboardCrud = () => import('@/views/admin/DashboardCrud')
const AdminUserCrud = () => import('@/views/admin/AdminUserCrud')

// Inventory
const Dashboard = () => import('@/views/inventory/Dashboard')
//const QR_Entry = () => import('@/views/inventory/QR_Entry')
const LocationwiseEntry = () => import('@/views/inventory/LocationwiseEntry')
const ItemwiseEntry = () => import('@/views/inventory/ItemwiseEntry')
const LocationReports = () => import('@/views/inventory/LocationwiseReports')
const ItemReports = () => import('@/views/inventory/ItemwiseReports')

// Orders
const OrderEntry = () => import('@/views/orders/OrderEntry')
const Orders = () => import('@/views/orders/Orders')
const OutstandingPayments = () => import('@/views/orders/OutstandingPayments')
// const DeliveryQ = () => import('@/views/orders/DeliveryQ')
const Messages = () => import('@/views/orders/Messages')

// Reports
const CustomReports = () => import('@/views/inventory/CustomReports')       // Sales, Manufacturing
const ValueSummary = () => import('@/views/inventory/ValueSummaryReport')   // Inventory Value
const SaleAmtReport = () => import('@/views/orders/SaleAmtReport')    // Sales/Purchase - Amt 
const SaleQtyReport = () => import('@/views/orders/SaleQtyReport')    // Sales/Purchase - Quantity 
const CustomerOrderVolume = () => import('@/views/orders/CustomerOrderVolume')  // Customer Order Volume
const DistributionNetwork = () => import('@/views/orders/DistributionNetwork')  // Public - Dealer network

// Taxes
const GSTR = () => import('@/views/taxes/GSTR')

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Auth
// const Signin = () => import('@/views/auth/Signin')
// const Signup = () => import('@/views/auth/Signup')
// const ForgotPassword = () => import('@/views/auth/ForgotPassword')


Vue.use(Router)

export default new Router({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/Demo',
      name: '',
      component: TheContainer,
      children: [
        {
          path: '/:orgId',
          name: 'Home',
          component: Home
        },
        {
          path: '/:orgId/IL/dashboard/:dbNbr',
          name: 'Dashboard',
          component: Dashboard
        },

        // {
        //   path: '/IL/qrEntry',
        //   name: 'QR Entry',
        //   component: QR_Entry
        // },

        {
          path: '/:orgId/IL/locationwiseEntry/:location',
          name: 'Locationwise Entry',
          component: LocationwiseEntry
        },

        {
          path: '/:orgId/IL/itemwiseEntry/:item1',
          name: 'Itemwise Entry',
          component: ItemwiseEntry
        },

        {
          path: '/:orgId/IL/locationReports/:location',
          name: 'Location Reports',
          component: LocationReports
        },

        {
          path: '/:orgId/IL/itemReports/:item1',
          name: 'Item Reports',
          component: ItemReports
        },

        {
          path: '/:orgId/IL/customReports/:reportName/:orderType',
          name: 'Custom Reports',
          component: CustomReports
        },

        {
          path: '/:orgId/IL/valueSummary',
          name: 'Inventory Value',
          component: ValueSummary     
        },

        {
          path: '/:orgId/orders/orderEntry/:orderType/:location',
          name: 'Order Entry',
          component: OrderEntry     
        },

        {
          path: '/:orgId/orders/orders/:orderType/:opType/:location/:locationType',
          name: 'Orders',
          component: Orders     
        },

        {
          path: '/:orgId/orders/messages',
          name: 'Messages',
          component: Messages     
        },

        {
          path: '/:orgId/orders/outstandingPayments/:orderType/:location',
          name: 'OutstandingPayments',
          component: OutstandingPayments     
        },

        // {
        //   path: '/:orgId/orders/deliveryQ/:orderType/:opType',
        //   name: 'Delivery Queue',
        //   component: DeliveryQ     
        // },

        {
          path: '/:orgId/orders/SaleAmtReport/:orderType',
          name: 'SaleAmt Report',
          component: SaleAmtReport     
        },
        {
          path: '/:orgId/orders/SaleQtyReport/:orderType',
          name: 'SaleQty Report',
          component: SaleQtyReport     
        },

        {
          path: '/:orgId/taxes/GSTR/:orderType/:reportName',
          name: 'GSTR Report',
          component: GSTR     
        },
        {
          path: '/:orgId/orders/customerOrderVolume',
          name: 'Customer Order Volume',
          component: CustomerOrderVolume     
        },
        {
          path: '/:orgId/orders/distributors',
          name: 'Distributors',
          component: DistributionNetwork     
        },

        {
          path: '/:orgId/admin/contact',
          name: 'Contact SignLedger',
          component: Contact
        },
        {
          path: '/:orgId/admin/usermanual',
          name: 'Admin User Manual',
          component: UserManual
        },
        {
          path: '/:orgId/admin/partners/:partnerType',
          name: 'Partners',
          component: Partners
        },
        {
          path: '/:orgId/admin/org',
          name: 'Organization',
          component: Org
        },
        {
          path: '/:orgId/admin/item1s',
          name: 'Item1s',
          component: Item1s
        },
        {
          path: '/:orgId/admin/locations',
          name: 'Locations',
          component: Locations
        },
        {
          path: '/:orgId/admin/customReportsMaint/:reportName',
          name: 'CustomReportsCrud',
          component: CustomReportsCrud
        },
        {
          path: '/:orgId/admin/dashboardCrud/:dbNbr',
          name: 'DashboardCrud',
          component: DashboardCrud
        },
        {
          path: '/:orgId/admin/adminUserCrud',
          name: 'AdminUserCrud',
          component: AdminUserCrud
        },

        // {
        //   path: '/:orgId/auth/signin',
        //   name: 'Signin',
        //   component: Signin
        // },
        // {
        //   path: '/:orgId/auth/signup',
        //   name: 'Signup',
        //   component: Signup
        // },
        // {
        //   path: '/:orgId/auth/forgotPassword',
        //   name: 'Forgot Password',
        //   component: ForgotPassword
        // },

      ]
    }
  ]
}

