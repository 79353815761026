
// import Vue from 'vue'

// import GoogleLogin from 'vue-google-login';
// import { LoaderPlugin } from 'vue-google-login';
// Vue.use(LoaderPlugin, {
//     client_id: "826790578290-u5r5clu8ia10tr0l6931god4ctqt5shq.apps.googleusercontent.com"
// });


export default 
{
    methods: 
    {
      resetUserSession(){
        // Reset Local Storage
        localStorage.olUserId = ''
        localStorage.olUserOrg = ''
        localStorage.olEnableOL = ''
        localStorage.olUserToken = ''
        this.bus.$emit('userChanged')
        this.$router.push({ path: '/' })
      },

      getAuthToken(){
        var authToken = localStorage.olUserToken 

        if (!authToken){
            return ""
        }

        // If token is expired
        if ((localStorage.olTokenExpiresAt - Date.now()) <= 0){
          this.$alert("Login session is expired. Please Login again.")
          this.resetUserSession()
          return ""
        }

        // Token about to expire in 5 mins
        if ((localStorage.olTokenExpiresAt - Date.now()) < (5*60*1000)){
          this.$alert("Login session is about to expire. Please Logout and Login again to refresh session.")
        }

        return authToken
      },

    },
}

