<template>
  <router-view>
  </router-view>
</template>

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader"


export default {
    name: 'App',

    components: {
    },

    data() {
        return {
            // User state is checked in 'beforeMount' and then 'mount' is triggered
            beforeMountPromise: null,
            orgId: null,
        }
    },

	  methods:{
    },

}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
