// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
<g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 960 l0 -960 960 0 960 0 0 960 0 960 -960 0 -960 0 0 -960z m1154
851 c6 -4 28 -12 50 -15 21 -4 46 -11 55 -15 9 -5 48 -23 86 -42 39 -18 88
-45 110 -60 108 -72 225 -210 284 -334 19 -38 37 -77 41 -85 4 -9 11 -35 15
-58 4 -23 10 -44 15 -47 14 -8 21 -205 11 -295 -12 -116 -31 -177 -92 -300
-47 -94 -64 -116 -159 -210 -92 -92 -118 -112 -200 -152 -52 -26 -111 -51
-130 -57 -19 -6 -59 -18 -87 -26 -76 -24 -318 -23 -393 0 -191 59 -286 111
-397 218 -103 99 -147 163 -216 317 -41 89 -60 230 -52 380 4 63 10 120 15
125 4 6 11 27 14 49 4 21 11 46 16 55 4 9 22 47 40 86 18 38 53 97 78 130 43
57 156 175 168 175 2 0 14 9 25 19 11 10 59 38 107 61 48 24 94 46 103 51 9 4
34 11 55 15 22 3 44 10 49 15 6 4 33 10 60 12 75 7 316 -2 329 -12z"/>
<path d="M805 1561 c-89 -27 -112 -37 -186 -87 -266 -177 -357 -525 -211 -809
17 -33 43 -73 57 -90 14 -16 38 -44 53 -60 15 -17 63 -60 107 -95 44 -36 81
-68 83 -72 2 -5 12 -8 22 -8 10 0 22 -5 25 -11 4 -6 13 -8 21 -5 8 3 14 2 14
-2 0 -11 52 -16 165 -17 114 0 177 9 211 32 13 8 24 12 24 9 0 -8 58 23 78 42
7 6 23 12 35 12 15 0 18 3 10 8 -9 6 -3 14 19 27 34 20 128 114 128 128 0 4 5
5 12 1 7 -4 8 -3 4 5 -9 13 18 43 29 33 5 -4 5 -2 2 4 -3 6 2 26 12 45 15 29
29 67 59 159 14 46 15 197 1 273 -31 171 -168 345 -339 432 -126 64 -309 83
-435 46z m220 -171 c6 -4 27 -10 49 -13 63 -10 161 -63 220 -121 31 -30 56
-59 56 -65 0 -6 8 -19 18 -29 21 -21 52 -88 58 -128 15 -85 15 -110 -1 -177
-25 -103 -66 -175 -138 -244 -147 -140 -341 -175 -517 -94 -115 53 -229 183
-261 297 -20 73 -20 193 1 239 7 17 12 38 11 49 -2 11 2 16 8 12 6 -4 11 0 11
9 0 30 81 136 113 148 9 3 17 14 17 23 0 10 5 14 14 11 8 -3 20 1 26 9 7 8 17
14 23 14 6 0 18 6 26 14 15 13 81 33 129 40 12 2 26 6 29 10 9 9 95 5 108 -4z"/>
<path d="M908 1272 c-21 -4 -26 -49 -24 -234 1 -146 1 -147 29 -175 15 -16 27
-34 27 -41 0 -7 12 -12 27 -12 14 0 23 -3 20 -7 -4 -3 15 -17 41 -30 78 -40
97 -53 95 -62 -1 -4 10 -6 25 -4 19 3 23 2 14 -5 -10 -7 -3 -11 24 -15 41 -7
59 5 80 53 12 27 2 70 -16 70 -6 0 -29 12 -53 27 -23 15 -57 36 -76 46 -19 10
-51 30 -73 43 l-38 24 -1 123 c-1 138 -4 186 -12 194 -7 6 -65 10 -89 5z"/>
</g>
`]
